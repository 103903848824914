import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { logoutExpress } from "./functions/api";
import Swal from "sweetalert2";
import "./main.css";
//React components
import RegistrationLayout from "./components/registration/Registration_layout";
import RegistrationFinish from "./components/registration/Registration_finish";
import LoginLayout from "./components/login/Login_layout";
import Dashboard from "./components/dashboard/Dashboard";
import Teams from "./components/teams/Teams";
import Member from "./components/teams/Member";
import PrivateRoute from "./components/private_route/dashboard/PrivateRouteDashboard";
import PrivateRouteLogin from "./components/private_route/login/PrivateRouteLogin";
import ForgotPasswordLayout from "./components/forgot_password/Forgot_password_layout";
import ForgotPasswordFinish from "./components/forgot_password/Forgot_password_finish";
import ForgotPasswordRenew from "./components/forgot_password/Forgot_password_renew";
import PrivateRouteForgotPassword from "./components/private_route/forgot_password/PrivateRouteForgotPassword";
import RegisterMember from "./components/teams/teams_registration/RegisterMember";

//Rapportages
import Reports from "./components/reports/Reports";
import EditReport from "./components/reports/EditReport";
import Inspection from "./components/reports/create_report/Inspection";
import ReportResult from "./components/reports/create_report/ReportResult";
import Customers from "./components/customer/Customers";
import Inspections from "./components/inspections/inspections";

//Administration
import Administration from "./components/administration/Administration";
import CreateAdministration from "./components/administration/Create";

//Api function
import { check_auth } from "./functions/api";
import { check_forget_password_id } from "./functions/api";
import CreateCustomer from "./components/customer/create_customer/createCustomer";
import EditCustomer from "./components/customer/EditCustomer";
import EditInspections from "./components/inspections/edit/EditInspections";

var allCookies = document.cookie.split(";");
const rootElement = document.getElementById("root");
let authStatus;
let forgotPasswordIdStatus;

check_auth().then((response) => {
  authStatus = response;
  // Get permission from localstorage if account is logged in
  if (authStatus === 200) {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const webappLogin = permissions.loginPermissions.webappLogin;

    //check if account is active
    if (permissions.loginPermissions.isActive === "0") {
      for (let i = 0; i < allCookies.length; i++) {
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();
      }
      Swal.fire({
        title: "Account is innactief!",
        timer: 500,
        showConfirmButton: false,
      }).then(async () => {
        logoutExpress()
          .then((response) => response.json())
          .then((response) => {
            if (response.msg === "valid-logout") {
              // first remove cookies
              localStorage.clear();
              var allCookies = document.cookie.split(";");

              // The "expire" attribute of every cookie is
              // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
              for (let i = 0; i < allCookies.length; i++) {
                document.cookie =
                  allCookies[i] + "=;expires=" + new Date(0).toUTCString();
              }
              window.location.reload();
            }
          });
      });
    }

    // check if user has access to the webapp
    if (webappLogin === "0") {
      Swal.fire({
        title: "Geen toegang tot de webapp!",
        timer: 500,
        showConfirmButton: false,
      }).then(async () => {
        logoutExpress()
          .then((response) => response.json())
          .then((response) => {
            if (response.msg === "valid-logout") {
              // first remove cookies
              localStorage.clear();
              var allCookies = document.cookie.split(";");

              // The "expire" attribute of every cookie is
              // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
              for (let i = 0; i < allCookies.length; i++) {
                document.cookie =
                  allCookies[i] + "=;expires=" + new Date(0).toUTCString();
              }
              window.location.reload();
            }
          });
      });
    }
    if (
      permissions.viewPermissions.showRapportages === "0" &&
      permissions.viewPermissions.showTeams === "0" &&
      permissions.viewPermissions.showSubscription === "0"
    ) {
      for (let i = 0; i < allCookies.length; i++) {
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();
      }
      Swal.fire({
        title: "Geen toegang tot de webapp!",
        timer: 500,
        showConfirmButton: false,
      }).then(async () => {
        logoutExpress()
          .then((response) => response.json())
          .then((response) => {
            if (response.msg === "valid-logout") {
              // first remove cookies
              localStorage.clear();
              var allCookies = document.cookie.split(";");

              // The "expire" attribute of every cookie is
              // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
              for (let i = 0; i < allCookies.length; i++) {
                document.cookie =
                  allCookies[i] + "=;expires=" + new Date(0).toUTCString();
              }
              window.location.reload();
            }
          });
      });
    }
  }
});

let params = new URL(document.location).searchParams;
let token = params.get("id");

if (token !== null) {
  check_forget_password_id(token).then((response) => {
    forgotPasswordIdStatus = response;
  });
}

waitForElement();
function waitForElement() {
  if (typeof authStatus !== "undefined") {
    ReactDOM.render(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RegistrationLayout />} />
          <Route path="/finish" element={<RegistrationFinish />} />
          <Route
            path="/login"
            element={
              <PrivateRouteLogin is_auth={authStatus}>
                <LoginLayout />
              </PrivateRouteLogin>
            }
          />
          <Route
            path="inspections"
            element={
              <PrivateRoute is_auth={authStatus}> 
                <Inspections />
              </PrivateRoute>
            }
          />
          <Route
            path="inspections/edit_inspection/:id"
            element={
              <PrivateRoute is_auth={authStatus}>
                <EditInspections />
              </PrivateRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="teams"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Teams />
              </PrivateRoute>
            }
          />
          <Route
            path="member"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Member />
              </PrivateRoute>
            }
          />
          <Route path="/forgot_password" element={<ForgotPasswordLayout />} />
          <Route
            path="/forgot_password_finish"
            element={<ForgotPasswordFinish />}
          />
          <Route
            path="/forgot_password_renew"
            element={
              <PrivateRouteForgotPassword id_status={forgotPasswordIdStatus}>
                <ForgotPasswordRenew />
              </PrivateRouteForgotPassword>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="reports/edit_report"
            element={
              <PrivateRoute is_auth={authStatus}>
                <EditReport />
              </PrivateRoute>
            }
          />
          <Route
            path="reports/select_inspection"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Inspection />
              </PrivateRoute>
            }
          />
          <Route
            path="customers"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="customers/create"
            element={
              <PrivateRoute is_auth={authStatus}>
                <CreateCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path="customers/edit_customer"
            element={
              <PrivateRoute is_auth={authStatus}>
                <EditCustomer />
              </PrivateRoute>
            }
          />
          <Route
            path="reports/report_result"
            element={
              <PrivateRoute is_auth={authStatus}>
                <ReportResult />
              </PrivateRoute>
            }
          />
          <Route
            path="teams/register_member/:id/:token"
            element={<RegisterMember />}
          ></Route>
          <Route
            path="teams/edit_member/:id"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Member />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="administration"
            element={
              <PrivateRoute is_auth={authStatus}>
                <Administration />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="administration/create"
            element={
              <PrivateRoute is_auth={authStatus}>
                <CreateAdministration />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="administration/edit/:id"
            element={
              <PrivateRoute is_auth={authStatus}>
                <CreateAdministration />
              </PrivateRoute>
            }
          ></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>,
      rootElement
    );
  } else {
    setTimeout(waitForElement, 250);
  }
}
